import { useState, useEffect, createRef } from 'react'
import { useRouter } from 'next/router'
import eventService from 'lib/services/pixels-headless/events'
import styles from './NavSearch.module.scss'

function linkElement(event) {
  const linkParam = 'navSearch'

  const getEventLink = (linkSlug) => {
    if (linkParam) {
      return `/${linkSlug}?ref=${linkParam}`
    }

    return `/${linkSlug}`
  }

  return (
    <a
      href={`/tapahtuma${getEventLink(event.slug)}`}
      key={event.name}
      className={styles.eventLink}
    >
      <div className={styles.eventWrapper}>
        <div className={styles.event}>{event.name}</div>
      </div>
    </a>
  )
}

export default function NavSearch() {
  const router = useRouter()
  const [search, changeVal] = useState('')
  const [isFocused, changeFocus] = useState(false)
  const inputRef = createRef()
  const [allEvents, setAllEvents] = useState([])
  const [eventsToShow, setEventsToShow] = useState([])
  const [isFrontPage, setIsFrontPage] = useState(true)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isFocused) inputRef.current.focus()
  }, [isFocused])

  const searchUpdate = async ({ target }) => {
    const { value } = target
    changeVal(value)
    if (!inputRef.current.isFocused) inputRef.current.focus()
  }
  const focusSearch = async (e, isFocus = false) => {
    const visible = !!search || isFocus
    changeFocus(visible)
    if (isFocus && !search) {
      setLoading(true)
      const result = await eventService.getCompact()
      setAllEvents(result)
      setLoading(false)
    }
    if (!isFocus) {
      changeVal('')
      changeFocus(false)
    }
  }
  const handleSearch = async () => {
    await router.push({ pathname: '/haku', query: { search } })
  }

  const filterSearch = () => {
    const matches = allEvents.filter((event) =>
      event.name.toLowerCase().includes(search.toLowerCase()),
    )
    if (matches.length > 5) {
      setEventsToShow(matches.slice(0, 5))
    } else {
      setEventsToShow(matches)
    }
  }

  useEffect(() => {
    filterSearch()
  }, [search])

  useEffect(() => {
    changeFocus(false)
    changeVal('')
    if (router && router.asPath.length <= 1) {
      setIsFrontPage(true)
    } else {
      setIsFrontPage(false)
    }
  }, [router])

  const handleFocusSearchTrue = (e) => {
    focusSearch(e, true)
  }

  const handleInputOnInput = (e) => !loading && searchUpdate(e)

  const handleInputOnBlur = (e) =>
    e.relatedTarget === null && focusSearch(e, false)

  const handleInputOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSpanOnClick = () => {
    searchUpdate({ target: { value: '' } })
  }

  return (
    <>
      <div
        className={`${styles.searchField}`}
        typing={isFocused ? 'true' : undefined}
        onClick={handleFocusSearchTrue}
      >
        <span className={styles.searchIcon}></span>
        {isFocused && (
          <>
            <input
              ref={inputRef}
              type="text"
              value={search}
              className={styles.inputStyle}
              onInput={handleInputOnInput}
              onFocus={handleFocusSearchTrue}
              onBlur={handleInputOnBlur}
              onKeyDown={handleInputOnKeyDown}
            />
            {!!search && (
              <span
                className={styles.clearIcon}
                onClick={handleSpanOnClick}
              ></span>
            )}
          </>
        )}
      </div>
      {loading && isFocused && (
        <div
          className={isFrontPage ? styles.searchWrapFront : styles.searchWrap}
        >
          <div className={styles.row}>
            <div className={styles.colSpinner}>
              <img src="/img/loading.svg" className={styles.spinner} />
            </div>
            <div className={styles.colText}>
              <p className={styles.loadingText}>Kohta tapahtuu...</p>
            </div>
          </div>
        </div>
      )}
      {search && (
        <>
          <div
            className={isFrontPage ? styles.searchWrapFront : styles.searchWrap}
          >
            {!loading && eventsToShow.length === 0 && search.length !== 0 && (
              <p className={styles.noResultsText}>
                Ei tuloksia. Kokeile muuttaa hakuehtoja.
              </p>
            )}
            {!loading && eventsToShow.map(linkElement)}
          </div>
        </>
      )}
    </>
  )
}
