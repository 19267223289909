import { useRouter } from 'next/router'

// Constants.
import { typesToPrefixes } from 'lib/constants/navigations'

export const formatLink = (slug, type) => {
  const { locale, defaultLocale } = useRouter()
  const localePrefix =
    locale === defaultLocale && locale !== undefined ? locale : ''
  const urlPrefix = typesToPrefixes[type] ? typesToPrefixes[type] : ''
  const href = localePrefix + urlPrefix + slug

  return href
}

export default {
  formatLink,
}
