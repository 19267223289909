import { useState, useEffect } from 'react'
import Router from 'next/router'
import styles from './Hamburger.module.scss'

export default function Hamburger({ open, altStyle = false }) {
  const [isOpen, setIsOpen] = useState(false)

  const openMenu = () => {
    setIsOpen(!isOpen)
    open()
  }

  const getHamburgerClass = () => {
    if (isOpen) {
      return `${styles.hamburger} ${styles.open}`
    }

    return styles.hamburger
  }

  const getPartStyle = () => {
    if (altStyle) {
      return `${styles.part} ${styles.altPart}`
    }

    return styles.part
  }

  // Reset hamburger state on navigation events.
  useEffect(() => {
    if (Router?.events?.on) {
      Router.events.on('routeChangeStart', () => setIsOpen(false))
    }
  }, [])

  return (
    <div
      role="button"
      aria-label="Avaa valikko"
      className={getHamburgerClass()}
      onClick={() => openMenu()}
    >
      <span className={getPartStyle()} />
      <span className={getPartStyle()} />
      <span className={getPartStyle()} />
    </div>
  )
}
