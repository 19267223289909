import Link from 'next/link'
import styles from './CartLink.module.scss'

export default function CartLink({ cartCount = 0, altStyle = false }) {
  const getLinkAmount = () => {
    if (cartCount === 0) {
      return ''
    }

    return `(${cartCount})`
  }

  const getLinkStyle = () => {
    if (altStyle) {
      return `${styles.link} ${styles.altLink}`
    }

    return styles.link
  }

  return (
    <Link href="/tarjouskori">
      <a className={getLinkStyle()}>
        <img
          className={cartCount === 0 ? styles.iconNoMargin : styles.icon}
          src={altStyle ? '/img/cart-white.svg' : '/img/cart.svg'}
          width="20"
          height="20"
          alt="Tarjouskorin ikoni"
          loading="lazy"
        />
        <div className={altStyle ? styles.amountWhite : styles.amount}>
          {getLinkAmount()}
        </div>
      </a>
    </Link>
  )
}
