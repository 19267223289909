import { useState, useEffect, createRef } from 'react'
import { useRouter } from 'next/router'
import eventService from 'lib/services/pixels-headless/events'
import styles from './SearchBar.module.scss'

function linkElement(event) {
  const linkParam = 'navSearch'

  const getEventLink = (linkSlug) => {
    if (linkParam) {
      return `/${linkSlug}?ref=${linkParam}`
    }

    return `/${linkSlug}`
  }

  return (
    <a
      href={`/tapahtuma${getEventLink(event.slug)}`}
      key={event.name}
      className={styles.eventLink}
    >
      <div className={`${styles.event} mobileSearchBarResult`}>
        {event.name}
      </div>
    </a>
  )
}

export default function SearchBar(props) {
  const router = useRouter()
  const [search, changeVal] = useState('')
  const [isFocused, changeFocus] = useState(false)
  const inputRef = createRef()
  const [allEvents, setAllEvents] = useState([])
  const [eventsToShow, setEventsToShow] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    inputRef.current.focus()
  }, [isFocused, props.inputOpen])

  const searchUpdate = async ({ target }) => {
    const { value } = target
    changeVal(value)
    if (!inputRef.current.isFocused) inputRef.current.focus()
  }

  const filterSearch = () => {
    const matches = allEvents.filter((event) =>
      event.name.toLowerCase().includes(search.toLowerCase()),
    )
    if (matches.length > 5) {
      setEventsToShow(matches.slice(0, 5))
    } else {
      setEventsToShow(matches)
    }
  }

  useEffect(() => {
    filterSearch()
  }, [search])

  const focusSearch = async (e, isFocus = false) => {
    const visible = !!search || isFocus
    changeFocus(visible)
    if (!isFocus) props.closeSearch()
    if (isFocus && !search) {
      setLoading(true)
      const result = await eventService.getCompact()
      setAllEvents(result)
      setLoading(false)
    }
  }

  const handleSearch = async () => {
    router.push({ pathname: '/haku', query: { search } })
  }

  const handleFocusSearchTrue = (e) => {
    focusSearch(e, true)
  }

  const handleFocusSearchFalse = (e) => {
    focusSearch(e, false)
  }

  const handleInputOnInput = (e) => !loading && searchUpdate(e)

  const handleInputOnKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  const handleSpanOnClick = (e) => {
    searchUpdate({ target: { value: '' } })
    focusSearch(e, false)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.inputArea} onClick={handleFocusSearchTrue}>
        <input
          ref={inputRef}
          type="text"
          value={search}
          onInput={handleInputOnInput}
          onFocus={handleFocusSearchTrue}
          onKeyDown={handleInputOnKeyDown}
          className={styles.inputStyle}
          id="mobileSearchBarInput"
        />
        {!!search && (
          <span className={styles.clearIcon} onClick={handleSpanOnClick}></span>
        )}
      </div>
      {loading && (
        <div className={styles.searchWrap}>
          <div className={styles.row}>
            <div className={styles.colSpinner}>
              <img src="/img/loading.svg" className={styles.spinner} />
            </div>
            <div className={styles.colText}>
              <p className={styles.loadingText}>Kohta tapahtuu...</p>
            </div>
          </div>
        </div>
      )}
      {search && (
        <div className={styles.searchWrap}>
          {eventsToShow.length === 0 && search.length !== 0 && (
            <h6 className="my-3">Ei tuloksia. Kokeile muuttaa hakuehtoja.</h6>
          )}
          {eventsToShow.map(linkElement)}
        </div>
      )}
      <div className={styles.otherWrap} onClick={handleFocusSearchFalse}></div>
    </div>
  )
}
