import SocialLink from 'components/SocialLink'
import styles from './SocialLinks.module.scss'

export default function SocialLinks({ links }) {
  if (!links) {
    return null
  }

  const { facebook, twitter, linkedin, youtube, instagram } = links

  return (
    <section className={styles.section}>
      <ul className={`no-style ${styles.list}`}>
        {facebook && (
          <li className={styles.item}>
            <SocialLink link={facebook} type="facebook" />
          </li>
        )}

        {twitter && (
          <li className={styles.item}>
            <SocialLink link={twitter} type="twitter" />
          </li>
        )}

        {linkedin && (
          <li className={styles.item}>
            <SocialLink link={linkedin} type="linkedin" />
          </li>
        )}

        {youtube && (
          <li className={styles.item}>
            <SocialLink link={youtube} type="youtube" />
          </li>
        )}

        {instagram && (
          <li className={styles.item}>
            <SocialLink link={instagram} type="instagram" />
          </li>
        )}
      </ul>
    </section>
  )
}
