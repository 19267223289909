import { subscribeNewsletter } from 'lib/services/newsletter'
import { useState } from 'react'
import styles from './Newsletter.module.scss'

export default function Newsletter() {
  const [email, setEmail] = useState('')
  const [subscribed, setSubscribed] = useState(false)
  const handleClick = async () => {
    try {
      await subscribeNewsletter({ email })
      setSubscribed(true)
    } catch (e) {
      setEmail('')
      console.log(e)
    }
  }
  return (
    <section className={styles.section}>
      <h4 className={styles.title}>Haluatko ideoita ja inspiraatiota?</h4>

      <div className={styles.inputRow}>
        <label htmlFor="footer-email"></label>
        <input
          id={`footer-email`}
          name="email"
          type="email"
          value={email}
          placeholder="Sähköpostiosoite:"
          onChange={({ target }) => setEmail(target.value)}
        />
      </div>
      <button
        className="btn btn--outline-white w-100"
        onClick={handleClick}
        disabled={subscribed}
      >
        {!subscribed ? 'Tilaa uutiskirje' : 'Kiitos tilauksesta!'}
      </button>
    </section>
  )
}
