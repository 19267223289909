import Link from 'components/Link'
import { useState, useEffect } from 'react'
import styles from './CookieNotification.module.scss'
import store from '../../lib/services/store'

export default function CookieNotification() {
  const [userNotAcceptedCookies, setUserNotAcceptedCookies] = useState(true)

  const handleClick = () => {
    store.setCookiesAccepted(true)
    setUserNotAcceptedCookies(false)
  }

  useEffect(() => {
    if (store.getCookiesAccepted()) {
      setUserNotAcceptedCookies(false)
    } else {
      setUserNotAcceptedCookies(true)
    }
  }, [])

  return (
    <>
      {userNotAcceptedCookies && (
        <div className={`${styles.testcard} flex`}>
          <div className="container">
            <div className="flex center">
              <div className="col-8">
                <p className="my-3">
                  Tämä sivusto käyttää evästeitä. Tarkemmin evästeiden keräämiin
                  tietoihin voit tutustua &nbsp;
                  <span>
                    <Link
                      link={{
                        url: '/happensin-kayttoehdot-ja-tietosuojalauseke',
                        title: 'tietosuojaselosteestamme',
                        target: '/happensin-kayttoehdot-ja-tietosuojalauseke',
                      }}
                    ></Link>
                  </span>
                </p>
              </div>
              <div className="col-4 flex center">
                <button className="btn btn--primary" onClick={handleClick}>
                  Hyväksy evästeet
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <></>
    </>
  )
}
