import NextHead from 'next/head'
import { decodeSpecialChars } from 'lib/utils/decode'

export default function Head({ seo }) {
  /**
   * Decode hashed special chars.
   */
  const fixChars = (content) => decodeSpecialChars(content)

  const removeFinalSlash = (link) => {
    if (link.slice(-1) === '/') {
      return link.slice(0, -1)
    }

    return link
  }

  return (
    <NextHead>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      {seo ? (
        <>
          <title>{fixChars(seo.title)}</title>
          <meta name="description" content={fixChars(seo.meta.description)} />
          <meta name="og:title" content={fixChars(seo.title)} />
          <meta
            name="og:description"
            content={fixChars(seo.meta['og:description'])}
          />
          <meta property="og:image" content={fixChars(seo.meta['og:image'])} />
          <meta
            property="og:site_name"
            content={fixChars(seo.meta['og:site_name'])}
          />
          <meta property="og:locale" content={seo.meta['og:locale']} />
          <meta property="og:type" content="website" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={fixChars(fixChars(seo.title))} />
          <meta
            name="twitter:description"
            content={fixChars(seo.meta['og:description'])}
          />
          <meta
            property="twitter:image"
            content={fixChars(seo.meta['og:image'])}
          />

          {seo.alternative &&
            seo.alternative.map((alternative) => (
              <link
                rel="alternate"
                hrefLang={alternative.lang}
                href={process.env.NEXT_PUBLIC_SITE_URL + alternative.link}
                key={alternative.lang}
              />
            ))}

          <link rel="canonical" href={removeFinalSlash(seo.canonical)} />
        </>
      ) : null}
    </NextHead>
  )
}
