import Link from 'next/link'
import FooterNav from 'components/Navigation/Footer'
import FooterLegalNav from 'components/Navigation/FooterLegal'
import SocialLinks from 'components/SocialLinks'

// Styles.
import styles from './Footer.module.scss'
import Newsletter from './Newsletter'

export default function Footer({
  menuClients,
  menuProviders,
  menuLegal,
  socialLinks,
}) {
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className={styles.wrap}>
          <div className={styles.logoWrap}>
            <Link href="/" passHref>
              <img
                className={styles.logo}
                src="/img/logo-white.png"
                alt="Happens logo"
                width="114"
                height="30"
              />
            </Link>
            <p className={`extra-small ${styles.tagline}`}>
              Helppoa ja hauskaa aina kun tapahtuu
            </p>
            <div className={styles.socialWrap}>
              <SocialLinks links={socialLinks} />
            </div>
          </div>
          <section className={styles.menu}>
            <FooterNav nav={menuClients} title="Käyttäjille" />
          </section>

          <section className={styles.menu}>
            <FooterNav nav={menuProviders} title="Palveluntarjoajille" />
          </section>

          <section className={styles.newsletterWrap}>
            <Newsletter />
          </section>

          <section className={styles.legal}>
            <FooterLegalNav nav={menuLegal} className={styles.legalMenu} />

            <small className={styles.copyright}>
              All rights reserved {new Date().getFullYear()} © HPPNS Events Oy
            </small>
          </section>
        </div>
      </div>
    </footer>
  )
}
