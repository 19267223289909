/**
 * Decode special characters.
 * Common use case: SEO Framework breaks special chars.
 */
export const decodeSpecialChars = (content) => {
  let decodedContent = content

  if (content) {
    decodedContent = decodedContent.replace(new RegExp('&#x2d;', 'g'), '-')
    decodedContent = decodedContent.replace(new RegExp('&#038;', 'g'), '&')
    decodedContent = decodedContent.replace(new RegExp('&#8217;', 'g'), "'")
    decodedContent = decodedContent.replace(new RegExp('&#8230;', 'g'), '…')
  }

  return decodedContent
}

export function decodeHtml(html) {
  if (typeof window === 'undefined') return html
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

export default decodeSpecialChars
