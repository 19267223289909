/**
 * Map post types to "prefix" they should have in url.
 * For example, blogs would have /blog/
 */
export const typesToPrefixes = {
  page: '',
  post: '/blog',
  blogArchive: '',
  event_category: '/tapahtumatyyppi',
  event: '/tapahtuma',
  eventWithSlash: '/tapahtuma/',
}

export default typesToPrefixes
