import { toUnix } from 'lib/utils/dates'
import store from 'lib/services/store'

const defaultStructure = {
  inquiry: {
    location: '',
    eventType: '',
    dateTime: {
      date: toUnix(new Date()),
    },
    guests: '',
    isFlexible: false,
  },
  offerRequests: [
    // {
    //   id: 123,
    //   message: 'Lorem ipsum dolor sit message'
    // },
  ],
}

// Null offer when store is not yet available.
const defaultOffer = {
  id: null,
  location: null,
  eventType: null,
  dateTime: null,
  guests: 0,
  message: null,
  isFlexible: false,
}

/**
 * Get default response if no data.
 */
const getDefault = () => ({
  ...defaultStructure.inquiry,
  message: '',
})

/**
 * Get default offer structure.
 */
export const getDefaultOffer = () => defaultOffer

/**
 * Get general inquiry data
 */
export const getGeneralInquiry = () => {
  if (store.isAvailable()) {
    const inquiry = store.getInquiry()

    if (inquiry) {
      return inquiry
    }
  }

  return getDefault()
}

/**
 * Save general inquiry data.
 */
export const setGeneralInquiry = (inquiryData) => {
  if (store.isAvailable()) {
    const inquiry = { ...getDefault(), ...inquiryData }
    delete inquiry.message

    store.setInquiry(inquiry)
  }
}

/**
 * Get inquiry/offer data by id.
 *
 * @param int id
 */
export const getInquiry = (id) => {
  if (store.isAvailable()) {
    const offer = store.getOffer(id)

    if (offer) {
      return offer
    }
  }
  return getDefaultOffer()
}

export const saveInquiry = (
  id,
  location = null,
  eventType = null,
  dateTime = null,
  guests = null,
  message = null,
  isFlexible = false,
) => {
  const newOfferRequest = getDefaultOffer()

  // Only fill fields that were provided. Use defaults for others.
  if (id) newOfferRequest.id = id
  if (location) newOfferRequest.location = location
  if (eventType) newOfferRequest.eventType = eventType
  if (dateTime) newOfferRequest.dateTime = dateTime
  if (guests) newOfferRequest.guests = guests
  if (message) newOfferRequest.message = message
  if (isFlexible) newOfferRequest.isFlexible = isFlexible

  return store.addOffer(newOfferRequest)
}

export const getOffers = (id) => {
  if (store.isAvailable()) {
    const offers = store.getOffers()

    if (offers && Array.isArray(offers)) {
      return offers.filter((offer) => (id ? offer.id === id : true))
    }
  }

  return []
}

export const setOffers = (offers) => {
  if (store.isAvailable()) {
    store.setOffers(offers)
  }
}

export const getMessageTemplate = () => `Kuvaus tilaisuudesta: 
Kesto/Ajankohta: 
Toiveet ja lisäpalvelut: 
Muuta huomioitavaa: `

export const isValidMessage = (message, checkTemplate = true) => {
  if (
    message === undefined ||
    message === '' ||
    message === null ||
    message === getMessageTemplate()
  ) {
    return false
  }

  if (checkTemplate && message === getMessageTemplate()) {
    return false
  }

  return true
}

export const getOfferCount = () => getOffers().length

export default {
  getGeneralInquiry,
  setGeneralInquiry,
  getInquiry,
  saveInquiry,
  getOfferCount,
  getDefaultOffer,
}
