import Link from 'next/link'
import styles from './UserIcon.module.scss'

export default function UserIcon({ altStyle = false }) {
  return (
    <Link href="/oma-tili">
      <a>
        <img
          className={styles.icon}
          src={altStyle ? '/img/user-white.svg' : '/img/user.svg'}
          width="20"
          height="20"
          alt="Käyttäjätilin ikoni"
          loading="lazy"
        />
      </a>
    </Link>
  )
}
