import Link from 'next/link'
import MainNav from 'components/Navigation/MainNav/MainNav'
import styles from './SiteHeader.module.scss'

export default function SiteHeader({ nav, altStyle = false }) {
  const getNavStyle = () => {
    if (altStyle) {
      return `${styles.siteHeader} ${styles.alternative}`
    }

    return styles.siteHeader
  }
  return (
    <>
      <div className={styles.headerSpacer} />
      <section className={getNavStyle()}>
        <div className="container">
          <div className={styles.wrap}>
            <div className={styles.logoWrap}>
              <Link href="/">
                <a>
                  <img
                    src={
                      altStyle === true
                        ? '/img/logo-white.png'
                        : '/img/logo.png'
                    }
                    width="151"
                    height="38"
                    className={styles.logo}
                    alt="Happens logo"
                  />
                </a>
              </Link>
            </div>
            <div className={styles.navWrap}>
              <MainNav nav={nav} altStyle={altStyle} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
