import { signIn } from 'next-auth/client'
import Image from 'next/image'

export default function LoginButton({ altStyle = false }) {
  const getBtnClasses = () => {
    if (altStyle) {
      return 'btn btn--login'
    }

    return 'btn btn--login'
  }

  return (
    <a className={getBtnClasses()} onClick={() => signIn()}>
      <Image
        src={altStyle ? '/img/user-white.svg' : '/img/user-pink.svg'}
        layout="fill"
        objectFit="contain"
        objectPosition="top center"
        priority
      />
    </a>
  )
}
