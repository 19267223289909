import { useState, useEffect } from 'react'
import { useRouter } from 'next/router'
import { signOut } from 'next-auth/client'
import { emptyCart } from 'lib/services/cart'
import styles from './AccountDropdown.module.scss'

export default function AccountDropdown({ altStyle = false }) {
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const getClasses = () => {
    if (altStyle) {
      return `${styles.myAccount} ${styles.alternative}`
    }

    return styles.myAccount
  }

  /**
   * Log out action, clear selections.
   */
  const logOut = () => {
    emptyCart()
    signOut()
  }

  // Close account drodpown on route change.
  useEffect(() => {
    const onRouteChange = () => setIsOpen(false)

    if (router?.events?.on) {
      router.events.on('routeChangeStart', onRouteChange)
    }

    return function cleanup() {
      router.events.off('routeChangeStart', onRouteChange)
    }
  }, [])

  return (
    <div className={styles.section}>
      <p className={getClasses()} role="button" onClick={toggleDropdown}>
        Oma tili
      </p>
      {isOpen && (
        <div className={styles.dropdown}>
          <ul className="no-style">
            <li
              role="button"
              onClick={() => router.push('/oma-tili')}
              className={styles.item}
            >
              Tarjouspyyntöni
            </li>
            <li role="button" onClick={logOut} className={styles.item}>
              Kirjaudu ulos
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
