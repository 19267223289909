// Components.
import Head from 'components/Head/Head'
import Footer from 'components/Footer'
import SiteHeader from 'components/SiteHeader'
import MobileHeader from 'components/MobileHeader'
import PreviewBanner from 'components/PreviewBanner'
import CookieNotification from 'components/CookieNotification'

export default function Layout({ children, data, preview = false }) {
  const { menus, options } = data

  const defineSeoFields = () => {
    if (data && 'seo' in data) {
      return data.seo
    }

    return null
  }

  return (
    <div>
      <Head seo={defineSeoFields()}></Head>
      <main>
        <SiteHeader nav={menus.desktop} altStyle={data.title === 'Etusivu'} />
        <MobileHeader nav={menus.mobile} altStyle={data.title === 'Etusivu'} />
        {children}
      </main>
      <Footer
        menuClients={menus.footer_clients}
        menuProviders={menus.footer_providers}
        menuLegal={menus.footer_legal}
        socialLinks={options.social}
      ></Footer>
      <CookieNotification />

      {preview && <PreviewBanner />}
    </div>
  )
}
