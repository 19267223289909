import { useSession } from 'next-auth/client'

// Components.
import LoginButton from './LoginButton'
import AccountDropdown from './AccountDropdown'

/**
 * Uses next-auth for all logic.
 */
export default function SignInOrOut({ altStyle = false }) {
  const [session] = useSession()

  return (
    <>
      {!session && <LoginButton altStyle={altStyle} />}
      {session && <AccountDropdown altStyle={altStyle} />}
    </>
  )
}
