import Link from 'next/link'
import NavItem from 'components/Navigation/NavItem'
import styles from './Menu.module.scss'

export default function Menu({ nav }) {
  return (
    <div className={styles.section}>
      <div className={styles.top}>
        <Link href="/">
          <a>
            <img
              src={'/img/logo-black.png'}
              width="114"
              height="30"
              className={styles.logo}
              alt="Happens logo"
            />
          </a>
        </Link>
      </div>
      <div className={styles.content}>
        <ul className="no-style">
          {nav.map((navItem) => {
            if (navItem.children.length === 0) {
              return (
                <NavItem
                  data={navItem}
                  key={navItem.slug}
                  classes={styles.link}
                />
              )
            }
            return (
              <div key={navItem.slug}>
                <NavItem
                  key={navItem.slug}
                  data={navItem}
                  classes={styles.link}
                />
                <div className={styles.mobileWrapper}>
                  {navItem.children.map((childrenItem) => {
                    return (
                      <div key={childrenItem.slug}>
                        <NavItem
                          data={childrenItem}
                          key={childrenItem.slug}
                          classes={styles.link}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
