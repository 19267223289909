import { getTime } from 'date-fns'

/**
 * Wrapper for localStorage & sessionStorage
 * It is used as local store for user selections & non-sent inquiries
 * Short lived data is session storaged, while longer lived is in localstorage.
 */
const STORE_VERSION = 2
const INQUIRY_KEY = `happens_inquiry_${STORE_VERSION}`
const OFFERS_KEY = `happens_offers_${STORE_VERSION}`
const EVENT_KEY = 'storeChanged'
const ADDITIONAL_FILTERS_KEY = `happens_additional_filters_${STORE_VERSION}`
const PAGINATIONS_KEY = `happens_current_page_${STORE_VERSION}`
const COOKIES_KEY = 'cookies'
// Time to live for storage. 24h.
const TTL = 86400000

/**
 * Dispatch custom action when store changes
 * Allows others for listen to changes.
 */
const storeAction = () => {
  const event = new CustomEvent(EVENT_KEY)
  window.dispatchEvent(event)
}

const isAvailable = () => {
  if (typeof window === 'undefined') {
    return false
  }

  return true
}

const getLocalObject = (key) => {
  const result = localStorage.getItem(key)

  if (result) {
    const parsedResult = JSON.parse(result)
    const currentTime = getTime(new Date())
    if (parsedResult.expiration >= currentTime) {
      return parsedResult.value
    }

    localStorage.removeItem(key)
    return null
  }

  return result
}

const setLocalObject = (key, value) => {
  const expiration = getTime(new Date()) + TTL
  const preparedValue = JSON.stringify({
    value,
    expiration,
  })

  localStorage.setItem(key, preparedValue)
  storeAction()
}

const getSessionObject = (key) => {
  const result = sessionStorage.getItem(key)

  if (result) {
    return JSON.parse(result)
  }

  return result
}

const setSessionObject = (key, value) => {
  const preparedValue = JSON.stringify(value)
  sessionStorage.setItem(key, preparedValue)
  storeAction()
}

/**
 * Get global inquiry data.
 */
const getInquiry = () => getLocalObject(INQUIRY_KEY)

/**
 * Set global inquiry data.
 */
const setInquiry = (inquiry) => setLocalObject(INQUIRY_KEY, inquiry)

/**
 * Get offer requests from store.
 */
const getOffers = () => getLocalObject(OFFERS_KEY)

/**
 * Set offer requests array to store.
 */
const setOffers = (offers) => setLocalObject(OFFERS_KEY, offers)

/**
 * Set cookies accepted for a day.
 */
const setCookiesAccepted = (bool) => {
  setLocalObject(COOKIES_KEY, bool)
}

/**
 * Get cookies info from store.
 */

const getCookiesAccepted = () => getLocalObject(COOKIES_KEY)

/**
 * Get userinfo in session.
 */
export const getUserInfo = (key) => {
  if (!isAvailable()) return
  return sessionStorage.getItem(key) || ''
}
/**
 * Set userinfo in session.
 */
export const setUserInfo = (key, value = '') => {
  if (!isAvailable()) return
  sessionStorage.setItem(key, value)
}

/**
 * Get offer requests from store.
 */
const getOffer = (id) => {
  const offers = getOffers() ?? []

  const offer = offers.filter((storeOffer) => storeOffer.id === id)

  if (offer.length > 0) {
    return offer[0]
  }

  return null
}

/**
 * Add or update offer in store.
 */
const addOffer = (newOffer) => {
  const offers = getOffers() ?? []
  let oldWasEdited = false

  const newOffers = offers.map((offer) => {
    if (offer.id === newOffer.id) {
      oldWasEdited = true
      return newOffer
    }

    return offer
  })

  if (!oldWasEdited) {
    newOffers.push(newOffer)
  }

  setOffers(newOffers)

  return true // This for success message.
}

/**
 * Set additional filters to store.
 */
const setAdditionalFilters = (filters) =>
  setSessionObject(ADDITIONAL_FILTERS_KEY, filters)

/**
 * Set additional filters to store.
 */
const getAdditionalFilters = () => getSessionObject(ADDITIONAL_FILTERS_KEY)

/**
 * Get paginated page from store.
 */
const getPagination = () => getSessionObject(PAGINATIONS_KEY)

/**
 * Set additional filters to store.
 */
const setPagination = (page) => setSessionObject(PAGINATIONS_KEY, page)

export default {
  isAvailable,
  getInquiry,
  setInquiry,
  getOffers,
  setOffers,
  getOffer,
  addOffer,
  setAdditionalFilters,
  getAdditionalFilters,
  getPagination,
  setPagination,
  setCookiesAccepted,
  getCookiesAccepted,
}
