import { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { getOfferCount } from 'lib/services/inquiry'
import Hamburger from 'components/Hamburger'
import CartLink from 'components/Navigation/CartLink'
import UserIcon from 'components/MobileHeader/UserIcon'
import MobileMenu from 'components/MobileHeader/Menu'
import useEventListener from '@use-it/event-listener'
import SearchBar from 'components/MobileHeader/SearchBar'
import SearchIcon from 'components/MobileHeader/SearchIcon'
import styles from './MobileHeader.module.scss'

export default function MobileHeader({ nav, altStyle = false }) {
  const [count, setCount] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const [inputOpen, setInputOpen] = useState(false)
  const router = useRouter()
  const isSearch = router.asPath.includes('/haku')

  const updateItemCount = () => {
    setCount(getOfferCount)
  }

  const getNavStyle = () => {
    if (altStyle) {
      return `${styles.mobileHeader} ${styles.alternative}`
    }

    return styles.mobileHeader
  }

  const toggleNav = () => {
    setIsOpen(!isOpen)
  }

  const closeNav = () => {
    setIsOpen(false)
  }

  const openSearch = () => {
    setInputOpen(true)
  }

  const closeSearch = () => {
    setInputOpen(false)
  }

  /**
   * Trigger item fetch on load.
   */
  useEffect(() => {
    updateItemCount()
  }, [nav])

  // Close tag nav on route change.
  useEffect(() => {
    closeNav()
  }, [router.asPath])

  useEffect(() => {
    setInputOpen(false)
  }, [router])

  /**
   * Listen for store changes.
   * Update number of offers when they're modified.
   */
  useEventListener('storeChanged', updateItemCount)

  return (
    <>
      <section className={`${getNavStyle()} ${isOpen ? styles.menuOpen : ''}`}>
        {inputOpen && (
          <SearchBar closeSearch={closeSearch} inputOpen={inputOpen} />
        )}
        <div className="container">
          <div className={inputOpen ? styles.hidden : styles.wrap}>
            <div className={styles.logoWrap}>
              <Hamburger altStyle={altStyle} open={toggleNav} />
              <Link href="/">
                <a>
                  <img
                    src={
                      altStyle === true
                        ? '/img/logo-white.png'
                        : '/img/logo.png'
                    }
                    width="151"
                    height="38"
                    className={styles.logo}
                    alt="Happens logo"
                  />
                </a>
              </Link>
            </div>
            <div className={styles.iconsWrap}>
              <CartLink cartCount={count} altStyle={altStyle} />
              <UserIcon altStyle={altStyle} />
              {!isSearch && (
                <SearchIcon openSearch={openSearch} altStyle={altStyle} />
              )}
            </div>
          </div>
          {isOpen && <MobileMenu nav={nav} />}
        </div>
      </section>
    </>
  )
}
