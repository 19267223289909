// Components.
import Link from 'next/link'
import { formatLink } from 'lib/utils/links'
import styles from './WrapperLink.module.scss'

export default function WrapperLink({ data, children, classes = false }) {
  const { type, slug } = data

  /**
   * Format link for next/link.
   * Takes locale & wp prefixes into account.
   */
  const href = formatLink(slug, type)

  const show = () => {}
  return (
    <Link href={href} onClick={show}>
      <a className={classes || styles.link}>{children}</a>
    </Link>
  )
}
