import axios from 'axios'

/**
 * Rest baseurl.
 */
const baseUrl = `${process.env.wordpress}wp-json/pixels-headless/v1/`

/**
 * Get event page data.
 */
const getEventPage = async (slug, preview = false) => {
  const response = await axios.get(
    `${baseUrl}event_page/${slug}?preview=${preview}`,
  )
  const { data } = response

  return data
}

/**
 * Get event category page data.
 */
const getEventCategoryPage = async (slug) => {
  const response = await axios.get(`${baseUrl}event_category?slug=${slug}`)

  const { data } = response

  return data
}

/**
 * Get list by array of ids
 */
const getList = async (ids) => {
  const response = await axios.get(
    `${baseUrl}event/list?ids=${JSON.stringify(ids)}`,
  )

  const { data } = response

  return data
}

/**
 * Get results by query
 */
const getResults = async (query, page = 1, orderBy = 'rand') => {
  // Filter out "all" selections.
  const queryToUse = query.filter((filter) => filter.values[0] !== 'all')
  const response = await axios.get(
    `${baseUrl}event/search?filters=${JSON.stringify(
      queryToUse,
    )}&page=${page}&orderby=${orderBy}`,
  )

  const { data } = response

  return data
}

/**
 * Get all events in compact form
 */
const getCompact = async () => {
  const response = await axios.get(`${baseUrl}event/compact`)

  const { data } = response

  return data
}

export default {
  getEventPage,
  getEventCategoryPage,
  getList,
  getResults,
  getCompact,
}
