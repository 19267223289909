import axios from 'axios'
/**
 * Send subscription request to local API endpoint.
 * @param data contains at least email but may include also name
 */
export const subscribeNewsletter = async (data) => {
  const result = await axios({
    method: 'post',
    url: '/api/newsletter',
    data,
  })

  return result.data
}

export default {
  subscribeNewsletter,
}
