import Link from 'components/Navigation/WrapperLink'
import styles from './FooterLegal.module.scss'

export default function FooterLegal({ nav }) {
  return (
    <nav>
      <ul className={`no-style ${styles.list}`}>
        {nav.map((item) => (
          <Link key={item.ID} data={{ slug: item.slug, type: item.type }}>
            <li className={styles.link}>{item.title}</li>
          </Link>
        ))}
      </ul>
    </nav>
  )
}
