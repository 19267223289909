import { useState } from 'react'
import { useSession } from 'next-auth/client'
import { getInquiry, getOffers, setOffers } from 'lib/services/inquiry'
import eventService from 'lib/services/pixels-headless/events'
import { getUserInfo } from 'lib/services/store/'

/**
 * Hook wrapper for form data needed in cart.
 */

export const useCartFormData = (sessionRequired = false) => {
  const defVals = {
    name: getUserInfo('name') || '',
    phone: getUserInfo('phone') || '',
    email: getUserInfo('email') || '',
    company: getUserInfo('company') || '',
    newsletter: getUserInfo('newsletter')
      ? JSON.parse(getUserInfo('newsletter'))
      : false,
  }

  const [session] = useSession({ required: sessionRequired })
  const [name, setName] = useState(defVals.name)
  const [phone, setPhone] = useState(defVals.phone)
  const [email, setEmail] = useState(defVals.email)
  const [company, setCompany] = useState(defVals.company)
  const [newsletter, setNewsletter] = useState(defVals.newsletter)

  // Required fields may have error state.
  const [nameError, setNameError] = useState(null)
  const [emailError, setEmailError] = useState(null)

  // Gather form data object.
  const formData = {
    name: {
      value: name,
      set: setName,
      error: nameError,
      setError: setNameError,
    },
    email: {
      value: email,
      set: setEmail,
      error: emailError,
      setError: setEmailError,
    },
    phone: {
      value: phone,
      set: setPhone,
    },
    company: {
      value: company,
      set: setCompany,
    },
    login_email: {
      value: session?.user?.email,
    },
    newsletter: {
      value: newsletter,
      set: setNewsletter,
    },
  }

  return formData
}

/**
 * Format cart items to include common inquiry data.
 */
const formatItems = (items) => {
  const formattedItems = items.map((item) => {
    const { location, eventType, dateTime, guests, message } = getInquiry(
      item.id,
    )

    return {
      ...item,
      location,
      eventType,
      dateTime,
      guests,
      message,
    }
  })

  return formattedItems
}

/**
 * Get cart item data.
 * Gets info from API based on IDs in store.
 */
export const getItems = async () => {
  const offers = getOffers()

  if (offers.length > 0) {
    const offerIDs = offers.map((offer) => offer.id)
    const cartItems = await eventService.getList(offerIDs)

    return formatItems(cartItems)
  }

  return []
}

/**
 * Remove item / offer from cart.
 */
export const removeItem = (id) => {
  const offers = getOffers()

  const newOffers = offers.filter((offer) => offer.id !== id)

  setOffers(newOffers)
}

/**
 * Check if item is in cart.
 */
export const isInCart = (id) => {
  const items = getOffers()

  return items.some((item) => item.id === id)
}

/**
 * Remove all items / offers from cart.
 */
export const emptyCart = () => {
  setOffers(null)
}

export default {
  useCartFormData,
  getItems,
  removeItem,
  emptyCart,
}
