export default function SocialLink({ link, type }) {
  const knownTypes = ['facebook', 'instagram', 'twitter', 'youtube', 'linkedin']

  if (!knownTypes.includes(type)) {
    return null
  }

  return (
    <a
      aria-label={`Sosiaalisen median linkki: ${type.toUpperCase()}`}
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        alt={type}
        width="24"
        height="24"
        loading="lazy"
        src={`/img/social/${type}.svg`}
      />
    </a>
  )
}
