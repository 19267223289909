// Components.
import Link from 'next/link'
import { formatLink } from 'lib/utils/links'

export default function NavItem({ data, classes = '' }) {
  const { type, slug, title } = data

  /**
   * Format link for next/link.
   * Takes locale & wp prefixes into account.
   */
  const href = formatLink(slug, type)

  return (
    <li>
      <Link href={href}>
        <a className={classes}>{title}</a>
      </Link>
    </li>
  )
}
