/**
 * Link component for ACF-like link bundles.
 */
export default function Link({
  link: { url, title, target },
  className: classes,
}) {
  return (
    <a
      className={classes}
      href={url}
      target={target !== '' ? target : null}
      rel={target === '_blank' ? 'noopener noreferrer' : null}
    >
      {title}
    </a>
  )
}
