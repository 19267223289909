import styles from './SearchIcon.module.scss'

export default function SearchIcon(props) {
  return (
    <img
      className={styles.icon}
      src={
        props.altStyle ? '/img/search-icon-white.svg' : '/img/search-icon.svg'
      }
      width="18"
      height="18"
      alt="Hakuikoni"
      loading="lazy"
      onClick={props.openSearch}
    />
  )
}
