import NavItem from 'components/Navigation/NavItem'
import styles from './Footer.module.scss'

export default function Footer({ nav, title }) {
  return (
    <nav>
      <h4 className={styles.title}>{title}</h4>
      <ul className="no-style">
        {nav.map((navItem) => (
          <NavItem data={navItem} key={navItem.slug} classes={styles.link} />
        ))}
      </ul>
    </nav>
  )
}
