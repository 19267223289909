import styles from './PreviewBanner.module.scss'

export default function PreviewBanner() {
  return (
    <div className={styles.section}>
      <a className="btn btn--primary" href="/api/exitpreview">
        Exit preview
      </a>
    </div>
  )
}
