import { useState, useEffect } from 'react'

import NavItem from 'components/Navigation/NavItem'
import CartLink from 'components/Navigation/CartLink'
import SignInOrOut from 'components/Navigation/SignInOrOut'
import NavSearch from 'components/Navigation/NavSearch'
import { getOfferCount } from 'lib/services/inquiry'
import useEventListener from '@use-it/event-listener'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { formatLink } from 'lib/utils/links'
import { useSession } from 'next-auth/client'
import styles from './MainNav.module.scss'

// Navbar
export default function MainNav({ nav, altStyle = false }) {
  const [session] = useSession()
  const router = useRouter()
  const isSearch = router.asPath.includes('/haku')
  const [count, setCount] = useState(0)
  const [isFrontPage, setIsFrontPage] = useState(false)
  const [elemWidth, setElemWidth] = useState(0)

  const updateItemCount = () => {
    setCount(getOfferCount)
  }

  const checkIfFrontPage = () => {
    if (
      router.asPath.toString() === '/' ||
      router.asPath.toString() === '' ||
      router.asPath.toString() === null
    ) {
      setIsFrontPage(true)
    } else {
      setIsFrontPage(false)
    }
  }

  const getLinkStyle = () => {
    if (altStyle) {
      return `${styles.link} ${styles.altLink}`
    }

    return styles.link
  }

  const handleWidthCalculations = (elem) => {
    if (typeof window !== 'undefined') {
      setElemWidth(document.getElementById(elem).clientWidth)
    }
  }

  /**
   * Trigger item fetch on load.
   */
  useEffect(() => {
    updateItemCount()
  }, [nav])

  useEffect(() => {
    checkIfFrontPage()
  }, [router])

  /**
   * Listen for store changes.
   * Update number of offers when they're modified.
   */
  useEventListener('storeChanged', updateItemCount)

  return (
    <nav>
      <ul className={`no-style ${styles.list}`}>
        {nav.map((navItem, index) => {
          if (navItem.children.length === 0) {
            return (
              <NavItem
                data={navItem}
                key={navItem.slug}
                classes={getLinkStyle()}
              />
            )
          }
          return (
            <div
              className={styles.dropdown}
              key={navItem.slug}
              id={`dropdown-${index}`}
              onMouseEnter={() => handleWidthCalculations(`dropdown-${index}`)}
            >
              <NavItem
                data={navItem}
                key={navItem.slug}
                classes={getLinkStyle()}
              />
              <span
                className={
                  isFrontPage ? styles.dropdownIconWhite : styles.dropdownIcon
                }
              ></span>
              <nav
                className={
                  isFrontPage ? styles.dropdownMenuLower : styles.dropdownMenu
                }
              >
                <div
                  className={
                    isFrontPage
                      ? styles.roundedWrapperLower
                      : styles.roundedWrapper
                  }
                  style={{ minWidth: elemWidth }}
                >
                  {navItem.children.map((childrenItem) => {
                    return (
                      <div key={childrenItem.slug}>
                        <Link
                          href={formatLink(
                            childrenItem.slug,
                            childrenItem.type,
                          )}
                        >
                          <a
                            className={styles.dropdownLink}
                            key={childrenItem.slug}
                          >
                            <li>{childrenItem.title}</li>
                          </a>
                        </Link>
                      </div>
                    )
                  })}
                </div>
              </nav>
            </div>
          )
        })}
        <li>
          <CartLink cartCount={count} altStyle={altStyle} />
        </li>
        <li className={session ? 'ps-0 ms-3' : 'ps-0 ms-3 mt-n3'}>
          <SignInOrOut altStyle={altStyle} />
        </li>
        {!isSearch && (
          <li className="ps-0 ms-3 mt-n3">
            <NavSearch />
          </li>
        )}
      </ul>
    </nav>
  )
}
